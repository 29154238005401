import * as React from "react";
import { css } from "@emotion/react";
import { t } from "@davingstone/utils";
import { Image } from "../Image";
import { Link } from "gatsby";
import { useLocation, WindowLocation } from "@reach/router";
import { useReturnToUrl } from "../../hooks/useReturnToUrl";

const imageSquareSize = 320;

export const ImageGallery = React.memo<{ imageSet?: string }>(props => {
  const gallery = useGetGallery(props.imageSet);
  const location = useLocation();

  const images = React.useMemo(
    () =>
      gallery.map(item => ({
        src: GalleryImageSizedUrl(
          item,
          {
            h: imageSquareSize * 2,
            w: imageSquareSize * 2
          },
          { location }
        ).toString(),
        linkTo: `/gallery-img/${btoa(item.url)}`
      })),
    [gallery]
  );

  return (
    <div
      css={css({
        display: "grid",

        gridAutoFlow: "row",
        gridTemplateColumns: `repeat(auto-fill, ${imageSquareSize}px)`,
        gridAutoRows: `${imageSquareSize}px`,
        justifyContent: "center",

        gap: "6px",
        paddingLeft: "24px",
        paddingRight: "24px",

        "@media (min-width: 360px)": {
          gridTemplateColumns: `repeat(auto-fill, ${imageSquareSize}px)`,
          gridTemplateRows: `repeat(auto-fill, ${imageSquareSize}px)`
        },

        "@media (min-width: 900px)": {
          gridTemplateColumns: `repeat(auto-fill, ${imageSquareSize}px)`,
          gridTemplateRows: `repeat(auto-fill, ${imageSquareSize}px)`
        }
      })}
    >
      {images.map(item => (
        <ImageLink key={item.src} to={item.linkTo} src={item.src} />
      ))}
    </div>
  );
});

const ImageLink = React.memo<{ to: string; src: string }>(props => {
  const { createReturnToUrl } = useReturnToUrl();

  const returnToUrl = React.useMemo(() => {
    return createReturnToUrl({
      path: props.to
    }).toString();
  }, [createReturnToUrl, props.to]);

  return (
    <Link to={returnToUrl}>
      <Image
        innerCss={css({
          width: "100%",
          objectFit: "cover",
          overflow: "hidden"
        })}
        src={props.src}
      />
    </Link>
  );
});

interface GalleryImageData {
  url: string;
}

const decodeRes = t.decodeOrThrow(
  t.array(
    t.strict({
      url: t.string
    })
  )
);

let cachedImageList: GalleryImageData[] = [];

const useGetGallery = (imageSet?: string): GalleryImageData[] => {
  const [list, setList] = React.useState(cachedImageList);

  React.useEffect(() => {
    fetch(`/api/image-list/${imageSet ?? "public"}`)
      .then(async res => {
        const json = await res.json();

        const decodedRes = decodeRes(json);

        cachedImageList = decodedRes;
        setList(decodedRes);
      })
      .catch(e => {
        console.error(e);
      });
  }, [setList, imageSet]);

  return list;
};

export const GalleryImageSizedUrl = (
  item: GalleryImageData | string,
  size: { h: number; w: number } | { h: number } | { w: number },
  opts: {
    location: WindowLocation;
  }
) => {
  const url = new URL(
    typeof item === "string" ? item : item.url,
    opts.location.origin
  );
  const params = url.searchParams;

  if ("h" in size) {
    params.append("h", size.h.toString());
  }

  if ("w" in size) {
    params.append("w", size.w.toString());
  }

  return url;
};
