import { css, Global } from "@emotion/react";
import emotionReset from "emotion-reset";
import * as React from "react";
import { theme } from "../../theme/index";

const globalStyles = css`
@import url("${theme.font.importURL}");

html {
}
body {
  margin: 0;
  padding: 0;
  font-family: ${theme.font.body};
}
`;

export const GlobalStyles = () => (
  <Global
    styles={globalStyles}
  />
);
