"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.nullable = exports.decodeOrThrow = exports.decodeOrUndefined = exports.CodecValidationError = void 0;
const Either_1 = require("fp-ts/lib/Either");
const t = __importStar(require("io-ts"));
const PathReporter_1 = require("io-ts/lib/PathReporter");
__exportStar(require("io-ts"), exports);
__exportStar(require("io-ts-types"), exports);
class CodecValidationError extends Error {
    constructor(codec, errors) {
        super(`Value violates codec: ${codec.name}`);
        this.errors = errors;
    }
}
exports.CodecValidationError = CodecValidationError;
function decodeOrUndefined(codec) {
    return (value) => {
        const result = codec.decode(value);
        if (Either_1.isLeft(result)) {
            return undefined;
        }
        return result.right;
    };
}
exports.decodeOrUndefined = decodeOrUndefined;
function decodeOrThrow(codec) {
    return (value) => {
        const result = codec.decode(value);
        if (Either_1.isLeft(result)) {
            throw new CodecValidationError(codec, PathReporter_1.PathReporter.report(result));
        }
        return result.right;
    };
}
exports.decodeOrThrow = decodeOrThrow;
function nullable(codec) {
    return t.union([codec, t.null, t.undefined]);
}
exports.nullable = nullable;
