import * as React from "react";
import { css, keyframes, SerializedStyles } from "@emotion/react";
import { useInView } from "react-intersection-observer";

const MAX_RETRIES = 5;

export const Image = React.memo<{
  src: string;
  innerCss?: any;
  loadingCss?: any;
  imageCss?: any;
}>(props => {
  const [state, dispatch] = React.useReducer(
    (
      state: {
        retryCount: number;
        loaded: boolean;
        failed: boolean;
      },
      action: "retry" | "loaded"
    ) => {
      switch (action) {
        case "loaded":
          return {
            ...state,
            loaded: true
          };
        case "retry":
          return {
            ...state,
            failed: state.retryCount >= MAX_RETRIES,
            retry: state.retryCount++
          };
      }
    },
    {
      retryCount: 0,
      loaded: false,
      failed: false
    }
  );

  const handleImageLoad = React.useCallback<
    (event: React.SyntheticEvent<HTMLImageElement, Event>) => void
  >(
    event => {
      dispatch("loaded");
    },
    [dispatch]
  );

  const handleImageError = React.useCallback<
    (event: React.SyntheticEvent<HTMLImageElement, Event>) => void
  >(() => {
    // retrying increments state, which updates key, and therefore retrys the request
    if (!state.failed) {
      setTimeout(() => dispatch("retry"), 300 * (state.retryCount ^ 2));
    }
  }, [dispatch, state.retryCount, state.failed]);

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
    trackVisibility: false,
    initialInView: false
  });

  const [hasBeenInView, setHasBeenInView] = React.useState(inView);

  React.useEffect(() => {
    setHasBeenInView(prev => prev || inView);
  }, [inView]);

  const renderFailed = state.failed;
  const renderImage = !renderFailed && hasBeenInView;
  const imageDisplayNone = !state.loaded;
  const renderLoading = !state.loaded && !renderFailed;
  // const imageDisplayNone = true;
  // const renderLoading = true;

  return (
    <div
      ref={ref}
      css={[
        css({
          width: "100%",
          height: "100%",
          animation: renderLoading
            ? `${fadeEffect} 1s ease infinite`
            : undefined
        }),
        props.innerCss,
        renderLoading ? props.loadingCss : undefined
      ]}
    >
      {renderFailed && "failed to load"}
      {renderImage && (
        <img
          // this triggers a retry
          key={state.retryCount}
          css={[
            css({
              width: "100%",
              objectFit: "cover",
              display: imageDisplayNone ? "none" : undefined
            }),
            props.imageCss
          ]}
          src={props.src}
          onLoad={handleImageLoad}
          onError={handleImageError}
        />
      )}
    </div>
  );
});

const fadeEffect = keyframes`
  from, to {
    background: #eee;
  }

  50% {
    background: #e0e0e0;
  }
`;
