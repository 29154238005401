import { mix, darken } from "polished";

// https://coolors.co/093824-8b2635-a40e4c-f8bdae-f9e7e7
const c1 = "#093824";
const c2 = "#8b2635";
const c3 = "#a40e4c";
const c4 = "#f8bdae";
const c5 = "#f9e7e7";
const d1 = "#9db99a";
const c6 = "#572428";

export const theme = {
  palette: {
    colours: [c1, c2, c3, c4, c5, d1, c6],
    colourMap: { c1, c2, c3, c4, c5, d1, c6 },
    light: {
      background: "white",
      text: "#333"
    },
    dark: {
      background: darken(0.1, c1),
      text: mix(0.2, c1, c5)
    }
  },
  palette2: {
    background1: "white",
    background2: mix(0.38, d1, "white")
  },
  font: {
    importURL:
      "https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;700&display=swap",
    header: "'EB Garamond', serif",
    body: "'EB Garamond', serif"
  }
};
