import { useLocation } from "@reach/router";
import * as React from "react";

const returnToURLKey = "returnTo";

export const useReturnToUrl = (params?: {
  origin?: string;
  defaultReturnToUrl?: string;
}) => {
  const location = useLocation();

  const origin = params?.origin ?? location.origin;
  const { href } = location;
  const defaultReturnToUrl = params?.defaultReturnToUrl ?? location.href;

  const createReturnToUrl = React.useCallback(
    (options: { url?: URL; path?: string; returnToPath?: string }) => {
      const url = options.url ?? new URL(options.path ?? "/", origin);
      const base64ReturnToPath = Buffer.from(
        options.returnToPath ?? defaultReturnToUrl
      ).toString("base64");
      url.searchParams.set(returnToURLKey, base64ReturnToPath);

      return url;
    },
    [origin]
  );

  const currentReturnToUrl = React.useMemo(() => {
    const url = new URL(href);
    const returnToUrlBase64 = url.searchParams.get(returnToURLKey) ?? undefined;

    if (returnToUrlBase64 !== undefined) {
      const returnToUrl = Buffer.from(returnToUrlBase64, "base64").toString();
      return returnToUrl;
    } else {
      return defaultReturnToUrl;
    }
  }, [defaultReturnToUrl, href]);

  return {
    createReturnToUrl,
    currentReturnToUrl
  };
};
